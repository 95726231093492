import React, { useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockContent, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, Accordian } from "../../components/Component";
import faqData from "../../assets/faqs.json";
import './Faq.css'

const Faq = () => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter categories and questions based on search term
  const filteredFaqs = faqData
    .map((category) => ({
      ...category,
      questions: category.questions.filter(
        (faq) =>
          faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((category) => category.questions.length > 0); // Remove empty categories

  return (
    <>
      <Head title="FAQ" />
      <Content>
        <div className="content-page wide-sm m-auto">
          <BlockHead size="lg" wide="xs" className="mx-auto">
            <BlockHeadContent className="text-center">
              
              <BlockTitle tag="h2" className="fw-normal">Frequently Asked Questions</BlockTitle>
              <BlockDes>
                <a className="lead">
                  Got a question? Type the keyword related to that question and you will probably find the answer here. If not, you can still contact our support team directly.
                </a>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>

          <Block>
            <input
              type="text"
              placeholder="Search FAQs by keywords..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control mb-4"
            />
            {filteredFaqs.length > 0 ? (
              <Accordian data={filteredFaqs} />
            ) : (
              <p className="text-center mt-4">No results found. Try a different search term.</p>
            )}
          </Block>

          <Block className="support-section mt-5">
  <BlockContent className="text-center p-4 rounded shadow-sm">
    <h4 className="fw-bold mb-3">We’re here to help you!</h4>
    <p className="text-muted mb-4">
      Have a question or need assistance? Our support team is ready to help.
      Feel free to reach out, and we'll respond promptly.
    </p>
    <Button color="primary" size="lg" className="btn-support"  onClick={() => {
        window.location.replace("/contact");
      }}>
      Get Support Now
    </Button>
  </BlockContent>
</Block>

        </div>
      </Content>
    </>
  );
};

export default Faq;
