import React, { useState } from "react";
import { Collapse } from "reactstrap";
import "./accordion.css";

const Accordion = ({ data, className, variation }) => {
  const [openCategory, setOpenCategory] = useState(null);
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category);
    setOpenQuestion(null); // Close any open question when toggling category
  };

  const toggleQuestion = (question) => {
    setOpenQuestion(openQuestion === question ? null : question);
  };

  return (
    <div className={`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`} style={{ marginTop: 10 }}>
      {/* Render Categories */}
      {data.map((category, index) => (
        <div key={index} className="accordion-category mb-4">
          {/* Category Header */}
          <div
            className={`accordion-category-head ${openCategory !== category.title ? "collapsed" : ""}`} 
            
            onClick={() => toggleCategory(category.title)}
          >
            <h4 className="category-title" style={{fontWeight: '200'}}>{category.title}</h4>
            <span className="accordion-icon"></span>
          </div>

          {/* Category Body */}
          <Collapse isOpen={openCategory === category.title}>
            <div className="accordion-category-body">
              {category.questions.map((faq, qIndex) => (
                <div key={qIndex} className="accordion-question">
                  {/* Question Header */}
                  <div
                    className={`accordion-question-head ${openQuestion !== faq.question ? "collapsed" : ""}`}
                    onClick={() => toggleQuestion(faq.question)}
                  >
                    <h6 className="question-title">{faq.question}</h6>
                    <span className="accordion-icon"></span>
                  </div>

                  {/* Question Answer */}
                  <Collapse isOpen={openQuestion === faq.question}>
                    <div className="accordion-question-body">
                      <p>{faq.answer}</p>
                    </div>
                  </Collapse>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
