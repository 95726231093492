import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Button } from 'reactstrap';
import { Icon } from '../../../components/Component';
import './PurchaseHistory.css';
const PurchaseHistory = () => {
  const [purchases, setPurchases] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPurchases = async () => {
      const data = await getUserPurchases();
      if (data && data.purchases) {
        setPurchases(data.purchases);
      }
    };

    fetchPurchases();
  }, []);

  const getUserPurchases = async () => {
    try {
      const token = Cookies.get('token');
      if (!token) {
        console.log('No token found');
        return null;
      }

      const response = await fetch(global.SERVIP + "/user/get_user_purchases", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        console.error('Failed to retrieve purchases:', response.status);
        return null;
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error while fetching user purchases:', error);
      return null;
    }
  };

  return (
    <div className="purchase-history">
      <h5 className="title">Purchase History</h5>
      {purchases.length > 0 ? (
        <div className="table-container">
          <table className="purchase-table">
            <thead>
              <tr>
                <th>Amount (USD)</th>
                <th>Date Created</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {purchases.map((purchase, index) => (
                <tr key={index}>
                  <td>${parseFloat(purchase.amount).toFixed(2)}</td>
                  <td>{new Date(purchase.createdAt).toLocaleDateString()}</td>
                  <td>{purchase.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="no-purchases">No purchases yet.</p>
      )}

      <Button
        className="purchase-button"
        color="primary"
        onClick={() => navigate('/dashboard/pricing')}
      >
        <Icon name="invest" className="button-icon" />
        Purchase Subscriptions
      </Button>
    </div>
  );
};

export default PurchaseHistory;
