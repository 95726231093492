import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { Button } from "reactstrap";
import { Icon } from "../../../components/Component";
import { bytesToMegaBytes } from "../../../utils/Utils";
import { iconsType } from "../../app/file-manager/components/Icons";
import ContactsGrid from "../../../components/contacts/ContactsGrid";
import { isValidPhoneNumber } from "libphonenumber-js";
import Cookies from "js-cookie"
import * as XLSX from 'xlsx';
import Swal from "sweetalert2";
import './UserGroup.css'
const UserGroup = ({ groupId, close, setGroups, deleteCallback, saveCallback }) => {
  const [name, setName] = useState('')
  const [fields, setFields] = useState([])
  const [contacts, setContacts] = useState([])

  const [rowData, setRowData] = useState([{}]);
  const getCellStyle = (params) => {
    const isLastRow = params.node.rowIndex === params.api.getDisplayedRowCount() - 1;

    if (!isLastRow && !isValidPhoneNumber(params.value || "")) {
      return { backgroundColor: '#ffc7c7', color: 'black' };
    }
    return null;
  };
  const DeleteCellRenderer = (props) => {
    const isLastRow = props.node.rowIndex === props.api.getDisplayedRowCount() - 1;

    const onDeleteRow = () => {
      setRowData(rowData => rowData.filter((_, index) => (props.node.rowIndex != index)))
    };

    return !isLastRow ? (
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8p5Q9D87iMIzM-_SZoyVg5KSbccZHC0U2Ow&usqp=CAU"
        alt="Delete"
        onClick={onDeleteRow}
        style={{ marginLeft: 5, fill: 'red', cursor: 'pointer' }}
        width={15}
        height={15}
      />
    ) : null;
  };



  const [columnDefs, setColumnDefs] = useState([
    {
      field: 'Phone Number',
      editable: true,
      cellEditor: 'agTextCellEditor',
      deletable: false,
      cellStyle: getCellStyle,
      sortable: false
    },
    {
      headerName: '',
      sortable: false,
      cellRenderer: DeleteCellRenderer,
      editable: false,
      width: 50
    }
  ])
  const handleDropChange = (acceptedFiles) => {
    const file = acceptedFiles[0]; // since only one file is allowed
  
    const reader = new FileReader();
    reader.onload = (e) => {
      let data = e.target.result;
      let workbook;
  
      if (file.type === "text/csv") {
        // Process CSV file
        data = new Uint8Array(data);
        workbook = XLSX.read(data, { type: 'array' });
      } else {
        // Process XLS or XLSX file
        try {
          workbook = XLSX.read(data, { type: 'binary' });
        } catch (e) {
          return alert("Invalid file");
        }
      }
  
      // Assuming you want to read the first sheet
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
  
      // Convert sheet to JSON
      const jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      const fields = jsonSheet[0].filter((e) => e !== 'phone_number');
      if (fields.length !== jsonSheet[0].length - 1) {
        return alert("Phone number column has to be called phone_number");
      }
  
      const phone_number_index = jsonSheet[0].indexOf('phone_number');
  
      const contacts = [];
      for (let i = 1; i < jsonSheet.length; i++) {
        const contact = { values: {} };
        let phone_number;
  
        try {
          phone_number = jsonSheet[i][phone_number_index]?.toString();
          if (
            phone_number &&
            (!phone_number.startsWith('+') && isValidPhoneNumber('+' + phone_number))
          ) {
            phone_number = '+' + phone_number;
          }
        } catch {
          // Skip this row if phone_number cannot be parsed
          continue;
        }
  
        if (!phone_number || !isValidPhoneNumber(phone_number)) {
          // Skip this row if phone_number is invalid
          continue;
        }
  
        contact.phone_number = phone_number;
  
        for (let j = 0; j < jsonSheet[i].length; j++) {
          if (j !== phone_number_index) {
            contact.values[jsonSheet[0][j]] =
              jsonSheet[i][j] !== undefined ? jsonSheet[i][j].toString() : "";
          }
        }
  
        contacts.push(contact);
      }
  
      setFields(fields);
      setContacts(contacts);
    };
  
    if (file.type === "text/csv") {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };
  


  const token = Cookies.get('token');

  const updateGroup = async (id, name, fields, contacts) => {
    try {
      const url = `${global.SERVIP}/outreach/groups/update`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, name, fields, contacts })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      if (saveCallback)
        saveCallback({ id, name, fields, contacts })

      return true;
    }
    catch (e) {
      console.log(e);
      return false;
    }
  }

  const fetchGroup = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/groups/get-full`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return;
      const data = await response.json();
      if (!data.ok)
        return;

      setName(data.group.name)
      setFields(data.group.fields)
      setContacts(data.group.contacts)
    }
    catch (e) {
      console.log(e);
    }
  }

  const deleteGroup = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/groups/delete`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      if (deleteCallback)
        deleteCallback(groupId);
      setGroups(groups => groups.filter(group => (group.id != groupId)));
      close();
      return true;
    }
    catch (e) {
      console.log(e);
      return false;
    }
  }

  useEffect(() => {
    if (groupId)
      fetchGroup(groupId);
  }, [groupId])


  useEffect(() => {
    
  }, [rowData]);

  const CustomHeader = props => {
    const [editing, setEditing] = useState(false);
    const [headerName, setHeaderName] = useState(props.displayName);

    const onDeleteColumn = async () => {
      const result = await Swal.fire({
        title: "Delete column",
        text: `Are you sure that you want to delete the column ${props.column.colDef.field}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      });

      if (result.isConfirmed) {
        setColumnDefs(prev => prev.filter(c => c.field !== props.column.colDef.field));
      }
    };

    const startEditing = () => setEditing(true);

    const stopEditing = () => {
      setEditing(false);
      props.column.colDef.headerName = headerName;
      props.api.refreshHeader();
    };

    const handleNameChange = (e) => setHeaderName(e.target.value);

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

        {editing ? (
          <input
            type="text"
            value={headerName}
            onChange={handleNameChange}
            onBlur={stopEditing}
            autoFocus
          />
        ) : (
          <div style={{ flexGrow: 1, display: 'flex', cursor: "", height: "100%" }} onClick={startEditing}>
            <span style={{ whiteSpace: 'nowrap' }}>{headerName}</span>
            <span style={{ flexGrow: 1 }} />
          </div>
        )}
        {props.column.colDef.deletable && (
          <img
            src="https://cdn-icons-png.flaticon.com/512/6861/6861362.png"
            alt="Delete"
            onClick={onDeleteColumn}
            style={{ marginLeft: 5, fill: 'red', cursor: 'pointer' }}
            width={20}
            height={20}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    setColumnDefs([
      {
        field: 'Phone Number',
        editable: true,
        cellEditor: 'agTextCellEditor',
        deletable: false,
        cellStyle: getCellStyle,
        sortable: false
      },
      ...(fields.map(e => ({
        field: e,
        editable: true,
        cellEditor: 'agTextCellEditor',
        headerComponent: CustomHeader,
        headerName: e,
        deletable: true
      }))),
      {
        headerName: '',
        sortable: false,
        cellRenderer: DeleteCellRenderer,
        editable: false,
        width: 50
      }
    ])
    setRowData([...(contacts.map(contact => ({
      'Phone Number': contact.phone_number,
      ...contact.values
    }))), {}])
  }, [fields, contacts])

  const saveGroup = async () => {
    for (let i = 0; i < rowData.length - 1; i++) {
      if (!isValidPhoneNumber(rowData[i]['Phone Number'] || "")) {
        alert("Invalid phone number")
        return;
      }
    }

    const newFields = columnDefs.filter(e => (e.field && (e.field != 'Phone Number'))).map(e => e.field);
    const newContacts = rowData.slice(0, -1).map(data => {
      let values = { ...data };
      const phone_number = data['Phone Number'];
      delete values['Phone Number'];
      return { phone_number, values };
    });
    if (await updateGroup(groupId, name, newFields, newContacts)) {
      setGroups(groups => groups.map(group => ((group.id != groupId) ? group : { ...group, name, contacts_length: newContacts.length })))
      setFields(newFields);
      setContacts(newContacts);
    }
    else {
      alert("Error");
    }
    close();

  }


  return (
    <React.Fragment>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          close();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="modal-body modal-body-lg">

        <h5 className="title mb-3">Group options</h5>
        <p>Enter the group name, used for your reference (e.g. Customers, Employees, Subscribers)</p>
        <input className="form-control form-control-md" placeholder="Group name" style={{ display: "flex", flexGrow: 1, marginBottom: 10 }} value={name} onChange={(e) => setName(e.target.value)} />
        {(rowData.length <= 1) ? <div className="nk-upload-form">
          <h5 className="title mb-3">Upload File with recipients (csv,xlx)</h5>
          <Dropzone
  onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
  accept={['.csv', '.xls', '.xlsx']} // Use an array for file extensions
>
  {({ getRootProps, getInputProps }) => (
    <section>
      <div
        {...getRootProps()}
        className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
      >
        <input {...getInputProps()} />
        <div className="dz-message">
          <span className="dz-message-text">
            <span>Drag and drop</span> file here or <span>browse</span>
          </span>
        </div>
      </div>
    </section>
  )}
</Dropzone>

        </div> : <div className="nk-upload-form">
          <h5 className="title mb-3">Edit group</h5>
        </div>}
        <div className="form-control-wrap">
          <div className="input-group">
            <ContactsGrid columnDefs={columnDefs} setColumnDefs={setColumnDefs} rowData={rowData} setRowData={setRowData} CustomHeader={CustomHeader} ></ContactsGrid>
          </div>
        </div>
        <div style={{ marginBottom: 100 }}></div>
        <div className="nk-modal-action justify-between" >
          <ul className="btn-toolbar g-4 align-center">
            <li>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  Swal.fire({
                    title: "Delete group",
                    text: "Are you sure that you want to delete this group? This action is irreversible.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete this group",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const result = await deleteGroup(groupId);
                      if (result)
                        Swal.fire({ title: "Success", text: "Successfully deleted group", icon: "success" });
                      else
                        Swal.fire({ title: "Error", text: "Could not delete group", icon: "error" });
                    }
                  });
                }}
                className="link link-danger"
              >
                Delete group
              </a>
            </li>
          </ul>
          <ul className="btn-toolbar g-4 align-center">
            <li>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  close();
                }}
                className="link link-primary"
              >
                Cancel
              </a>
            </li>
            <li>
              <Button onClick={saveGroup} color="primary">
                Save
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserGroup;
