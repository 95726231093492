import React, { useEffect, useState } from 'react';
import { Button, Input, FormGroup, Form } from 'reactstrap';
import { useRef } from 'react';

const CreateMessageComponent = ({ fields, firstContactValues, contactCountries, setMessagePrice, setTemplateText }) => {
    const [template, setTemplate] = useState("");
    const [history, setHistory] = useState([template]);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [pricing, setPricing] = useState({});
    const [characterCount, setCharacterCount] = useState(0);
    const [segmentCount, setSegmentCount] = useState(0);
    const templateRef = useRef(null);

    useEffect(() => {
        setTemplateText(template);
    }, [template]);

    const fetchCountries = async () => {
        try {
            const response = await fetch(`${global.SERVIP}/countries/get-all`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let pricing = {};
            for (let country of data) {
                pricing[country.iso2] = country.sms_buy_price;
            }
            setPricing(pricing);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    const calculateSegments = () => {
        const gsmCharset =
            /^[A-Za-z0-9 @£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,\-./:;<=>?¡ÄÖÑÜ§¿äöñüà\n\r]*$/;
        const message = generateMessageForRecipient(firstContactValues);
        const isGsm = gsmCharset.test(message);

        const singleSegmentLimit = isGsm ? 160 : 70;
        const multiSegmentLimit = isGsm ? 153 : 67;

        const length = message.length;
        setCharacterCount(length);

        if (length <= singleSegmentLimit) {
            setSegmentCount(1);
        } else {
            setSegmentCount(Math.ceil(length / multiSegmentLimit));
        }

        const smsCount = Math.ceil(length / multiSegmentLimit);
        let sum = 0;
        for (let iso2 in contactCountries) {
            if (pricing[iso2]) {
                sum += contactCountries[iso2] * pricing[iso2];
            }
        }
        sum *= smsCount;
        setMessagePrice(sum);
    };

    useEffect(() => {
        calculateSegments();
    }, [template, firstContactValues, pricing]);

    const insertFieldAtCursor = (field) => {
        const cursorPosition = templateRef.current.selectionStart;
        const textBeforeCursor = template.slice(0, cursorPosition);
        const textAfterCursor = template.slice(cursorPosition);
        const updatedTemplate = textBeforeCursor + `{${field}}` + textAfterCursor;

        updateTemplate(updatedTemplate);
    };

    const updateTemplate = (newTemplate) => {
        const newHistory = history.slice(0, historyIndex + 1);
        newHistory.push(newTemplate);
        setHistory(newHistory);
        setHistoryIndex(newHistory.length - 1);
        setTemplate(newTemplate);
    };

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') {
            if (historyIndex > 0) {
                setTemplate(history[historyIndex - 1]);
                setHistoryIndex(historyIndex - 1);
            }
            e.preventDefault();
        }
    };

    const generateMessageForRecipient = (recipient) => {
        if (!recipient) {
            return "You need to add contacts to group in order to be able to see the preview.";
        }
        let message = template;
        Object.keys(recipient).forEach(key => {
            message = message.replace(new RegExp(`{${key}}`, 'g'), recipient[key]);
        });
        return message;
    };

    return (
        <div style={{ padding: "20px", fontFamily: 'Arial, sans-serif' }}>
            <Form>
                <h5 style={{ marginBottom: '20px' }}>Message Template</h5>
                {fields.map(e => (
                    <Button 
                        key={e}
                        className="btn-dim" 
                        outline color="primary" 
                        onClick={() => insertFieldAtCursor(e)} 
                        style={{ marginRight: 10, marginBottom: 10 }}
                    >
                        Insert {e}
                    </Button>
                ))}
                <FormGroup style={{ marginTop: '20px' }}>
                    <Input
                        placeholder='Hello {name}, your order to {address} has been delivered'
                        type="textarea"
                        name="text"
                        id="messageTemplate"
                        innerRef={templateRef}
                        value={template}
                        onChange={(e) => updateTemplate(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ height: "200px", marginBottom: "20px", padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                </FormGroup>
            </Form>
            <div style={{ marginTop: '30px', padding: '20px', border: '1px solid #eaeaea', borderRadius: '8px', background: '#f9f9f9' }}>
                <h5 style={{ marginBottom: '10px' }}>Generated Message Preview</h5>
                <p style={{ fontStyle: 'italic', marginBottom: '20px' }}>{generateMessageForRecipient(firstContactValues)}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '14px', color: '#333' }}>
                        <strong>Character Count:</strong> {characterCount}
                    </div>
                    <div style={{ fontSize: '14px', color: '#333' }}>
                        <strong>SMS Segments:</strong> {segmentCount}
                    </div>
                </div>
                <p style={{ marginTop: '10px', fontSize: '12px', color: '#888' }}>
                    Note: The actual message length may vary depending on dynamic fields.
                </p>
            </div>
        </div>
    );
};

export default CreateMessageComponent;
