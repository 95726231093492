const menu = [
  // Messaging Section
  {
    heading: "Messaging",
    admin: false,
  },
  {
    icon: "send",
    text: "Test Services",
    active: false,
    link: "/dashboard/test",
    keyWords: ["test", "api", "send", "send sms"],
    description: "Send a message now and test services.",
    admin: true,
  },
  {
    icon: "chat",
    text: "Chat App",
    link: "/dashboard/chat",
    active: false,
    keyWords: ["chat", "sms"],
    description: "View your sent messages in a chat-like interface.",
    admin: false,
  },
  {
    icon: "comments",
    text: "No-Code Bulk SMS",
    active: false,
    subMenu: [
      {
        text: "Dashboard",
        link: "/dashboard/bulksms-dashboard",
        keyWords: ["bulk sms", "campaign", "dashboard"],
        description: "Check your Bulk SMS campaigns activity.",
      },
      {
        text: "Create Campaign",
        link: "/dashboard/bulksms-create",
        keyWords: ["bulk sms", "campaign", "create"],
        description: "Create a new Bulk SMS campaign.",
      },
    ],
    admin: false,
  },
  {
    heading: "Integrations",
    admin: false,
  },
  {
    icon: "network",
    text: "API & Hooks",
    link: "/dashboard/api",
    active: false,
    keyWords: ["api", "integration", "development"],
    description: "Integrate our services using our robust API.",
    admin: false,
  },
  // Devices Section
  {
    heading: "Devices",
    admin: true,
  },
  {
    icon: "mobile",
    text: "Devices and Setup",
    active: false,
    subMenu: [
      {
        text: "Tutorial",
        link: "/dashboard/your-devices/get-started",
        keyWords: ["devices", "setup", "tutorial"],
        description: "Learn how to set up your devices.",
      },
      {
        text: "Dashboard",
        link: "/dashboard/your-devices/overview",
        keyWords: ["devices", "dashboard"],
        description: "Overview of your devices and SIM cards.",
      },
    ],
    admin: true,
  },
  {
    heading: "Resources",
    admin: false,
  },
  // Integration Section
  {
    icon: "question-alt",
    text: "Help & Resources",
    link: "/dashboard/help",
    active: false,
    keyWords: ["help", "integration", "support", "faq", "resources"],
    description: "Quickly find the solution to your problem.",
    admin: true,
  },
  {
    icon: "video",
    text: "Tutorials",
    link: "/dashboard/tutorials",
    active: false,
    keyWords: ["api", "integration", "development", "tutorials", "tutorial"],
    description: "Step by step guides",
    admin: true,
  },
  {
    heading: "Pricing",
    admin: true,
  },
  {
    icon: "coin-alt",
    text: "Plans & Billing",
    link: "/dashboard/pricing",
    active: false,
    keyWords: ["pricing", "plans", "subscription"],
    description: "Explore pricing plans suited to your needs.",
    admin: true,
  },
  // Account Section
  {
    heading: "Account Management",
    admin: false,
  },

  {
    icon: "text-rich",
    text: "Account",
    link: "/dashboard/user",
    active: false,
    keyWords: ["account", "profile"],
    description: "Access your account details and settings.",
    admin: false,
  },
  {
    icon: "line-chart-up",
    text: "Balance and Analytics",
    active: false,
    link: "/dashboard/analytics",
    keyWords: ["analytics", "statistics", "usage"],
    description: "Analyze your service usage and performance.",
    admin: true,
  },
 
  {
    icon: "users",
    text: "Team",
    link: "/dashboard/team",
    active: false,
    keyWords: ["team", "management"],
    description: "Manage your team members.",
    admin: true,
  },
  // Pricing Section

];

export default menu;
