import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import { Icon } from "../../../components/Component";
import { Button } from "reactstrap";
import '../api.css'
import Cookies from "js-cookie"
import { useState } from "react";
import Swal from "sweetalert2";

const CopyButton = ({ apiKey, clicked }) => {
  const [buttonText, setButtonText] = useState("Copy");

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey).then(() => {
      setButtonText("Copied");
      setTimeout(() => setButtonText("Copy"), 1500); // Reset text after 3 seconds
    }, () => {
      // Handle any errors here
      console.error("Failed to copy API key");
    });
  };

  return (
    <Button className="btn-dim btn-sm" outline color="primary" onClick={handleCopy}>
      {clicked ? "Copied" : buttonText}
    </Button>
  );
};

const deleteAlert = async () => {
  Swal.fire({
    title: "Do you want to delete this api key?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete this key",
  }).then(async (result) => {
    if (result.isConfirmed) {

      Swal.fire("Api key deleteted, it will no longer function");


    }
  });
};

const DropdownTrans = ({ item, token, apiKeys, setApiKeys }) => {
  const masterKey = apiKeys[0].master ? apiKeys[0] : (() => {
    for (let apiKey of apiKeys)
      if (apiKey.master)
        return apiKey;
    return null;
  })();

  return (
    <UncontrolledDropdown>
      <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
        <Icon name="more-h"></Icon>
      </DropdownToggle>
      <DropdownMenu end>
        <ul className="link-list-plain">
          {
            (!item.master && parseFloat(masterKey.credits) > 0) && <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault(); // Prevent default anchor link behavior

                  const result = await Swal.fire({
                    title: 'Enter the amount',
                    input: 'text',
                    inputAttributes: {
                      autocapitalize: 'off',
                      placeholder: 'Amount'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Increase balance',
                    showLoaderOnConfirm: true,
                    preConfirm: (amount) => {
                      const amountToAdd = Number(amount);

                      if (!amountToAdd) {
                        Swal.showValidationMessage(`Please enter a valid amount`);
                        return;
                      }

                      if (amountToAdd <= 0) {
                        Swal.showValidationMessage(`Please enter a valid amount`);
                        return;
                      }

                      if (amountToAdd > parseFloat(masterKey.credits)) {
                        Swal.showValidationMessage(`Cannot add more than ${parseFloat(masterKey.credits)} credits`);
                        return;
                      }
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                  })
                  if (!result.isConfirmed)
                    return;

                  try {
                    const response = await fetch(`${global.SERVIP}/user/add-credits-api-key`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                      },
                      body: JSON.stringify({ id: item.id, amount: result.value })
                    });

                    if (!response.ok)
                      return Swal.fire({ title: 'Error!', text: 'Unknown error has occured.', icon: 'error' });

                    const data = await response.json();
                    if (!data.ok)
                      Swal.fire({ title: 'Error!', text: 'Unknown error has occured.', icon: 'error' });

                    for (let i = 0; i < apiKeys.length; i++) {
                      if (apiKeys[i].id == item.id) {
                        apiKeys[i].credits = parseFloat(apiKeys[i].credits) + parseFloat(result.value);
                      }
                      if (apiKeys[i].id == masterKey.id) {
                        apiKeys[i].credits = parseFloat(apiKeys[i].credits) - parseFloat(result.value);
                      }
                    }
                    setApiKeys([...apiKeys]);
                    Swal.fire({ title: 'Success!', text: 'Balance has been added.', icon: 'success' });
                  }
                  catch (e) {
                    Swal.fire({ title: 'Error!', text: 'Unknown error has occured.', icon: 'error' });
                  }
                }}
              >
                Increase balance
              </DropdownItem>
            </li>
          }
          {
            (!item.master && item.credits > 0) && <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault();
                  const result = await Swal.fire({
                    title: 'Enter the amount',
                    input: 'text',
                    inputAttributes: {
                      autocapitalize: 'off',
                      placeholder: 'Amount'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Decrease balance',
                    showLoaderOnConfirm: true,
                    preConfirm: (amount) => {
                      const amountToAdd = Number(amount);

                      if (!amountToAdd) {
                        Swal.showValidationMessage(`Please enter a valid amount`);
                        return;
                      }

                      if (amountToAdd <= 0) {
                        Swal.showValidationMessage(`Please enter a valid amount`);
                        return;
                      }

                      if (amountToAdd > parseFloat(item.credits)) {
                        Swal.showValidationMessage(`Cannot decrease more than ${parseFloat(item.credits)} credits`);
                        return;
                      }
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                  })
                  if (!result.isConfirmed)
                    return;

                  try {
                    const response = await fetch(`${global.SERVIP}/user/remove-credits-api-key`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                      },
                      body: JSON.stringify({ id: item.id, amount: result.value })
                    });

                    if (!response.ok)
                      return Swal.fire({ title: 'Error!', text: 'Unknown error has occured.', icon: 'error' });

                    const data = await response.json();
                    if (!data.ok)
                      Swal.fire({ title: 'Error!', text: 'Unknown error has occured.', icon: 'error' });

                    for (let i = 0; i < apiKeys.length; i++) {
                      if (apiKeys[i].id == item.id) {
                        apiKeys[i].credits = parseFloat(apiKeys[i].credits) - parseFloat(result.value);
                      }
                      if (apiKeys[i].id == masterKey.id) {
                        apiKeys[i].credits = parseFloat(apiKeys[i].credits) + parseFloat(result.value);
                      }
                    }
                    setApiKeys([...apiKeys]);
                    Swal.fire({ title: 'Success!', text: 'Balance has been decreased.', icon: 'success' });
                  }
                  catch (e) {
                    Swal.fire({ title: 'Error!', text: 'Unknown error has occured.', icon: 'error' });
                  }
                }}
              >
                Decrease balance
              </DropdownItem>
            </li>
          }
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={async (ev) => {
                ev.preventDefault();
                try {
                  const response = await fetch(`${global.SERVIP}/user/rotate-api-key`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ id: item.id })
                  });

                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }

                  const { ok, api_key } = await response.json();
                  if (!ok)
                    return alert("Could not regenerate API key");

                  setApiKeys(apiKeys.map(apiKey => (apiKey.id !== item.id) ? apiKey : { ...apiKey, api_key }));

                }
                catch (e) {
                  console.log(e);
                  console.error(e);
                }
              }}
            >
              Regenerate
            </DropdownItem>
          </li>

          {
            (!item.master) && <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault();
                  const result = await Swal.fire({
                    title: 'Are you sure?',
                    text: "Remaining credits will return to the master API key. This action cannot be undone.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                  });

                  if (result.isConfirmed) {
                    try {
                      const response = await fetch(`${global.SERVIP}/user/delete-api-key`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${token}` // Ensure `token` is defined in your component
                        },
                        body: JSON.stringify({ id: item.id })
                      });

                      if (!response.ok)
                        Swal.fire({ title: 'Error!', text: 'An error occurred while deleting the API key.', icon: 'error' });

                      const data = await response.json();

                      if (!data.ok)
                        Swal.fire({ title: 'Error!', text: 'An error occurred while deleting the API key.', icon: 'error' });

                      for (let i = 0; i < apiKeys.length; i++) {
                        if (apiKeys[i].id == masterKey.id) {
                          apiKeys[i].credits = parseFloat(apiKeys[i].credits) + parseFloat(item.credits);
                        }
                      }
                      setApiKeys(apiKeys.filter(e => (e.id != item.id)))

                      Swal.fire({ title: 'Deleted!', text: 'The API key has been deleted.', icon: 'success' });

                    } catch (error) {
                      Swal.fire({ title: 'Error!', text: 'An error occurred while deleting the API key.', icon: 'error' });
                    }
                  }
                }}
              >
                Delete
              </DropdownItem>
            </li>

          }
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown >
  );
};

function ApiKeyRow({ item, token, apiKeys, setApiKeys }) {
  const [clicked, setClicked] = useState(false);

  function formatString(str) {
    const totalMaxLen = 37;
    const padLen = 17;
    const ellipsis = '...';

    if (str.length > totalMaxLen) {
      const start = str.substring(0, padLen);
      const end = str.substring(str.length - padLen);
      str = start + ellipsis + end;
    }

    return str;
  }

  return (
    <tr className="tb-odr-item">
      <td className="tb-odr-info" title="Click to copy">
        <span className="tb-odr-id">
          <a
            href="#"
            onClick={(ev) => {
              navigator.clipboard.writeText(item.api_key).then(() => {
                setClicked(true);
                setTimeout(() => setClicked(false), 1500);
              }, () => {
                // Handle any errors here
                console.error("Failed to copy API key");
              });
              ev.preventDefault();
            }}
          >
            {formatString(item.api_key)}
          </a>
        </span>
      </td>
      <td className="tb-odr-amount tableColumn">
        <span className="tb-odr-total ">
           <span style={{ marginLeft: 5, fontWeight: "bold" }}>{item.master? "Master Key" : "Regular Key"}</span>
        </span>
      </td>
      <td className="tb-odr-action">
        {/* Ensure CopyButton and DropdownTrans components are defined elsewhere in your project */}
        <div className="tb-odr-btns d-none d-md-inline">
          <CopyButton apiKey={item.api_key} clicked={clicked} />
        </div>
        <DropdownTrans item={item} token={token} apiKeys={apiKeys} setApiKeys={setApiKeys} />
      </td>
    </tr>
  );
}

export const ApiKeyTable = ({ apiKeys, setApiKeys }) => {
  const token = Cookies.get('token');
  return (
    <table className="table table-orders">
      <thead className="tb-odr-head">
        <tr className="tb-odr-item">
          <th className="tb-odr-info">
            <span className="tb-odr-id">API Key</span>
          </th>
          <th className="tb-odr-amount">
            <span className="tb-odr-total d-none d-md-inline" >Key type</span>
          </th>

          <th className="tb-odr-action">&nbsp;</th>
        </tr>
      </thead>
      <tbody className="tb-odr-body">
        {apiKeys.map(item => <ApiKeyRow key={item.api_key} item={item} token={token} apiKeys={apiKeys} setApiKeys={setApiKeys} />)}
      </tbody>
    </table>
  );
};