import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockHead, BlockHeadContent, BlockTitle, BlockDes } from "../../components/Component";
import { Card, CardBody, CardTitle, CardText, Button, Row, Col } from "reactstrap";
import { useNavigate } from "react-router";

const Tutorials = () => {
    const navigate = useNavigate()
  const tutorials = [
    {
      title: "TextLink Setup Tutorial",
      description: "Learn how to set up TextLink on your Android device and start sending SMS seamlessly.",
      videoUrl: "https://www.youtube.com/embed/nyVm4U5iScg",
      serviceUrl: "/dashboard/bulksms-create"
    },
    {
      title: "How to Use Bulk SMS",
      description: "Discover how to use TextLink's Bulk SMS tool to send messages efficiently.",
      videoUrl: "https://www.youtube.com/embed/-gajhLRMYIE",
      serviceUrl: "/dashboard/your-devices/get-started"
    }
  ];

  return (
    <>
      <Head title="Tutorials" />
      <Content>
        {/* Header Section */}
        <Block size="md">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">Tutorials</BlockTitle>
              <BlockDes>
                <p>Explore these step-by-step video tutorials to make the most out of TextLink's features.</p>
                <br />
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
        </Block>

        {/* Tutorials Section */}
        <Row className="g-4">
          {tutorials.map((tutorial, index) => (
            <Col lg="5" md="12" key={index} className="mb-4">
              <Card className="card-bordered shadow-sm h-100" style={{ borderRadius: "12px", overflow: "hidden" }}>
                <div className="ratio ratio-16x9">
                  <iframe
                    src={tutorial.videoUrl}
                    title={tutorial.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="card-img-top"
                    style={{ borderRadius: "0" }}
                  ></iframe>
                </div>
                <CardBody className="card-inner d-flex flex-column">
                  <CardTitle tag="h4" className="mb-2">{tutorial.title}</CardTitle>
                  <CardText className="mb-4">{tutorial.description}</CardText>
                  <div className="mt-auto text-center">
                    <Button
                      color="primary"
                      href={tutorial.serviceUrl}
                      target="_blank"
                      className="w-100"
                      onClick={()=>navigate(tutorial.serviceUrl)}
                    >
                      Go to Service
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Additional Section Example */}
        
      </Content>
    </>
  );
};

export default Tutorials;
