function formatMetaText(date) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date >= today) {
        return 'Today';
    } else if (date >= yesterday) {
        return 'Yesterday';
    } else {
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric'
        });
    }
}
function formatFullDate(date) {
    return `${date.toLocaleDateString('en-US', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: date.getFullYear() === new Date().getFullYear() ? undefined : 'numeric'
    })}, ${date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    })}`;
}

// Helper function to format the date
// Helper function to format the date
function formatDate(date) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const dateOptions = {
        day: 'numeric',
        month: 'long',
        year: date.getFullYear() === now.getFullYear() ? undefined : 'numeric'
    };

    if (date >= today) {
        return `Today, ${date.toLocaleDateString('en-US', dateOptions)}`;
    } else if (date >= yesterday) {
        return `Yesterday, ${date.toLocaleDateString('en-US', dateOptions)}`;
    } else {
        return date.toLocaleDateString('en-US', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: date.getFullYear() === now.getFullYear() ? undefined : 'numeric'
        });
    }
}


function parseMessages(messages) {
    let parsedMessages = [];
    let lastDate = null;

    for (const messageString of messages) {
        const messageObj = JSON.parse(messageString);
        const date = new Date(messageObj.timestamp);
        const metaText = formatMetaText(date);

        if (!lastDate || lastDate !== metaText) {
            // Create a meta object for the date
            parsedMessages.push({
                meta: true,
                metaID: date.toISOString(),
                metaText: metaText
            });
            lastDate = metaText;
        }

        // Create the message object with the full date
        parsedMessages.push({
            id: messageObj.id,
            chat: messageObj.chat,
            me: messageObj.me,
            sim_card_id: messageObj.sim_card_id,
            timestamp: messageObj.timestamp,
            date: formatFullDate(date), // Full date format in the message
            status: messageObj.status
        });
    };

    return parsedMessages;
}

// Function to append a message
function appendMessage(parsedMessages, newMessageString) {
    const newMessageObj = JSON.parse(newMessageString);
    const newMessageDate = new Date(newMessageObj.timestamp);
    const formattedMetaText = formatMetaText(newMessageDate);

    // Find the last real message (not meta)
    let lastRealMessage = null;
    for (let i = parsedMessages.length - 1; i >= 0; i--) {
        if (!parsedMessages[i].metaID) {
            lastRealMessage = parsedMessages[i];
            break;
        }
    }

    const lastRealMessageDate = lastRealMessage ? new Date(lastRealMessage.timestamp) : null;
    const formattedLastRealDate = lastRealMessage ? formatMetaText(lastRealMessageDate) : null;

    if (formattedLastRealDate !== formattedMetaText) {
        parsedMessages.push({
            meta: true,
            metaID: newMessageDate.toISOString(),
            metaText: formattedMetaText
        });
    }

    parsedMessages.push({
        id: newMessageObj.id,
        chat: newMessageObj.chat,
        me: newMessageObj.me,
        sim_card_id: newMessageObj.sim_card_id,
        timestamp: newMessageObj.timestamp,
        date: formatFullDate(newMessageDate),
        status: newMessageObj.status
    });

    return parsedMessages;
}

// Function to prepend a message with improved meta handling
function prependMessage(parsedMessages, newMessageString) {
    const newMessageObj = JSON.parse(newMessageString);
    const newMessageDate = new Date(newMessageObj.timestamp);
    const formattedMetaText = formatMetaText(newMessageDate);

    // Find the first real message (not meta) and the first meta
    let firstRealMessageIndex = -1;
    let firstMetaIndex = -1;

    for (let i = 0; i < parsedMessages.length; i++) {
        if (parsedMessages[i].metaID && firstMetaIndex === -1) {
            firstMetaIndex = i;
        }
        if (!parsedMessages[i].metaID) {
            firstRealMessageIndex = i;
            break;
        }
    }

    const firstRealMessageDate = firstRealMessageIndex !== -1 ? new Date(parsedMessages[firstRealMessageIndex].timestamp) : null;
    const formattedFirstRealDate = firstRealMessageIndex !== -1 ? formatMetaText(firstRealMessageDate) : null;

    // If correct meta already exists, insert the message right after it
    if (formattedMetaText === formattedFirstRealDate) {
        parsedMessages.splice(firstRealMessageIndex, 0, {
            id: newMessageObj.id,
            chat: newMessageObj.chat,
            me: newMessageObj.me,
            sim_card_id: newMessageObj.sim_card_id,
            timestamp: newMessageObj.timestamp,
            date: formatFullDate(newMessageDate),
            status: newMessageObj.status
        });
    } else {
        // If no correct meta exists, insert a new meta and then add the message after it
        parsedMessages.unshift({
            meta: true, 
            metaID: newMessageDate.toISOString(),
            metaText: formattedMetaText
        });

        parsedMessages.splice(1, 0, {
            id: newMessageObj.id,
            chat: newMessageObj.chat,
            me: newMessageObj.me,
            sim_card_id: newMessageObj.sim_card_id,
            timestamp: newMessageObj.timestamp,
            date: formatFullDate(newMessageDate), // Full date format in the message
            status: newMessageObj.status
        });
    }

    return parsedMessages;
}

export { parseMessages, appendMessage, prependMessage }